import React, { Component } from 'react';
import { Input, Select, Icon, Button } from 'antd';

import { addSearchedUser, getSeasons } from '../../firebase/db-functions';

import logo from '../../Assets/pubglogo.png';

import 'antd/dist/antd.css';
import './index.css';

const Search = Input.Search;
const { Option, OptGroup } = Select;

class Home extends Component {
  state = {
    region: 'steam',
    season: 'division.bro.official.pc-2018-03',
    option: true,
    status: true,
    seasons: null,
    currentSeason: ''
  };

  getData = value => {
    addSearchedUser(value, false);

    this.props.history.push(`/player/${this.state.region}/${value}`);
  };

  getStats = value => {
    addSearchedUser(value, false);

    this.props.history.push(
      `/season/${this.state.region}/${value}/${this.state.season}`
    );
  };

  onchange = region => {
    this.setState({ region });
  };

  onchangeSeason = season => {
    this.setState({ season });
  };

  switch = () => {
    this.setState({ option: !this.state.option, status: !this.state.status });
  };

  async componentDidMount() {
    const { seasons, currentSeason } = await getSeasons();
    this.setState({ seasons: seasons.reverse(), currentSeason })
  }

  render() {
    const Regions = (
      <Select
        onChange={this.onchange}
        defaultValue="steam"
        style={{ width: 90 }}
      >
        <OptGroup label="Platform">
          <Option value="steam">Steam</Option>
          <Option value="xbox">Xbox</Option>
          <Option value="psn">Psn</Option>
          <Option value="kakao">Kakao</Option>
        </OptGroup>
      </Select>
    );

    const { status, option, seasons, currentSeason } = this.state;

    const optValues = seasons ? seasons.map((season, index) => {
      return (<Option key={index} value={season.ID}>
        {season.Name}
    </Option>)
    }) : [];

    return (
      <div className="homeSearchContainer">
        <div className="welcomeText">Search for any PUBG player</div>
        <div className="">Username is case sensitive</div>
        <div className="homeContainer">
          <div className="Search">
            <Search
              style={{ maxWidth: 400 }}
              onSearch={status ? this.getData : this.getStats}
              placeholder="PUBG Username"
              enterButton={status ? 'Search' : 'Get Stats'}
              size="large"
              addonBefore={Regions}
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            />
          </div>

          {!option && (
            <div className="seasonContainer">
              <div className="seasonText">Select a season: </div>
              <div className="seasonSelect">
                <Select
                  onChange={this.onchangeSeason}
                  defaultValue='Seasons'
                  style={{
                    position: 'relative',
                    width: '165px',
                    left: '-25px'
                  }}
                >
                  <OptGroup label="Active Season">
                    <Option value={currentSeason.ID}>
                      {currentSeason.Name}
                    </Option>
                  </OptGroup>
                  <OptGroup label="Past Seasons">
                    {optValues}
                  </OptGroup>
                </Select>
              </div>
            </div>
          )}
          <div className="statusBtn">
            <Button
              onClick={this.switch}
              className={'buttons'}
              style={{ top: '20px' }}
              type="primary"
            >
              {`Switch to ${option ? ` seasonal stats` : ` recent data`}`}
            </Button>
          </div>
        </div>
        <div className="signInText">
          Sign in to access your profile and save your favorite games!
        </div>
        <div className="pubgImage">
          <img src={logo} alt="logo" className="imageLogo" />
        </div>
      </div>
    );
  }
}

export default Home;

import React from 'react';

import notfound from '../../Assets/notfound.gif';

import './index.css';
import 'antd/dist/antd.css';

const NotFound = () => (
  <div>
    <img src={notfound} alt={'Not Found'} className={'image404'} />
    <center className="text404">{'PAGE NOT FOUND'}</center>
  </div>
);
export default NotFound;

import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { BackTop } from 'antd';

import 'antd/dist/antd.css';
import './index.css';

class Article extends Component {
  async componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { content, title } = this.props.location.state;

    return (
      <div>
        <div className="articleTitle"> {title}</div>
        <div className="articleContainer">
          {renderHTML(content)}
          <BackTop />
        </div>
      </div>
    );
  }
}

export default Article;

import React, { Component } from 'react';
import { Spin } from 'antd';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import walkimg from '../../Assets/running.png';
import driving from '../../Assets/car.png';
import carburn from '../../Assets/carburn.png';

import './index.css';
import 'antd/dist/antd.css';

const GET_STATS_QUERY = gql`
  query getSeasonStats($region: String!, $playerId: String!, $season: String!) {
    getSeasonStats(region: $region, playerId: $playerId, season: $season) {
      assists
      damage
      deaths
      drivingDistance
      heals
      kdRatio
      kills
      longestGame
      longestKill
      mostKills
      revives
      rounds
      runningDistance
      suicides
      teamKills
      timePlayed
      top10s
      vehiclesDestroyed
      wins
    }
  }
`;

const GET_PLAYER_ID_QUERY = gql`
  query GetMatchesQuery($region: String!, $playerName: String!) {
    playerId(region: $region, playerName: $playerName)
  }
`;

class SeasonStats extends Component {
  state = {
    errorMessage: '',
    duplicate: false,
    season: this.props.season,
    player: this.props.player
  };

  renderStats = stats => {
    const {
      assists,
      damage,
      deaths,
      drivingDistance,
      heals,
      kdRatio,
      kills,
      longestGame,
      longestKill,
      mostKills,
      revives,
      rounds,
      runningDistance,
      suicides,
      teamKills,
      timePlayed,
      top10s,
      vehiclesDestroyed,
      wins
    } = stats;

    const { player } = this.state;

    const winRatio = parseInt((wins / rounds) * 100, 10) || 0;
    const winPct = wins + '[' + winRatio + '%]';
    const walkDistance = (runningDistance / 1000).toFixed(1) + ' km';
    const driveDistance = (drivingDistance / 1000).toFixed(1) + ' km';
    const top10Ratio = parseInt((top10s / rounds) * 100, 10) || 0;
    const top10Pct = top10s + '[' + top10Ratio + '%]';
    return (
      <div>
        <div className="nameTitle">{player} </div>
        <div className="playerstatsContainer">
          <div className="elementsContainer">
            <div className="element">
              <div className="elementTitle">Kills</div>
              <div className="elementValue">{kills}</div>
            </div>
            <div className="element">
              <div className="elementTitle">Assists</div>
              <div className="elementValue">{assists}</div>
            </div>
            <div className="element">
              <div className="elementTitle">Deaths</div>
              <div className="elementValue">{deaths}</div>
            </div>
          </div>
          <div className="elementsContainer">
            <div className="element">
              <div className="elementTitle">Wins</div>
              <div className="elementValue">{winPct}</div>
            </div>
            <div className="element">
              <div className="elementTitle">K/D</div>
              <div className="elementValue">{kdRatio}</div>
            </div>
            <div className="element">
              <div className="elementTitle">Top 10s</div>
              <div className="elementValue">{top10Pct}</div>
            </div>
          </div>
          <div className="elementsContainer">
            <div className="element">
              <div className="elementTitle">Rounds</div>
              <div className="elementValue">{rounds}</div>
            </div>
            <div className="element">
              <div className="elementTitle">Team Kills</div>
              <div className="elementValue">{teamKills}</div>
            </div>
            <div className="element">
              <div className="elementTitle">Suicides</div>
              <div className="elementValue">{suicides}</div>
            </div>
          </div>
          <div className="elementsContainer">
            <div className="element">
              <div className="elementTitle">
                <img src={walkimg} alt="Smiley face" className="images" />
              </div>
              <div className="elementValue">{walkDistance}</div>
            </div>
            <div className="element">
              <div className="elementTitle">
                <img src={driving} alt="Smiley face" className="images" />
              </div>
              <div className="elementValue">{driveDistance}</div>
            </div>
            <div className="element">
              <div className="elementTitle">
                <img src={carburn} alt="Smiley face" className="images" />
              </div>
              <div className="elementValue">{vehiclesDestroyed}</div>
            </div>
          </div>
          <div className="elementsContainer">
            <div className="element">
              <div className="elementTitle">Heals</div>
              <div className="elementValue">{heals}</div>
            </div>
            <div className="element">
              <div className="elementTitle">Revives</div>
              <div className="elementValue">{revives}</div>
            </div>
            <div className="element">
              <div className="elementTitle">Damage</div>
              <div className="elementValue">{damage}</div>
            </div>
          </div>
          <div className="elementsContainer">
            <div className="element">
              <div className="elementTitle">Most Kills</div>
              <div className="elementValue">{mostKills}</div>
            </div>
            <div className="element">
              <div className="elementTitle">Longest Kill</div>
              <div className="elementValue">
                {parseInt(longestKill, 10) + ' m'}
              </div>
            </div>
          </div>
          <div className="elementsContainer">
            <div className="element">
              <div className="elementTitle">Time played</div>
              <div className="elementValue">
                {(parseInt(timePlayed, 10) / 60).toFixed(1) + ' hours'}
              </div>
            </div>
            <div className="element">
              <div className="elementTitle">Longest Game</div>
              <div className="elementValue">{longestGame + ' mins'}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderLoader = () => {
    return (
      <div className="loading2">
        <Spin tip="Getting your stats.." spinning={this.state.spinning} />
      </div>
    );
  };

  renderError = () => {
    return (
      <div className="error"> Didn't find this player in this region </div>
    );
  };

  render() {
    const { getSeasonStats } = this.props.GetSeasonStatsQuery;
    const { error } = this.props.GetPlayerIdQuery;

    return error
      ? this.renderError()
      : getSeasonStats
      ? this.renderStats(getSeasonStats)
      : this.renderLoader();
  }
}

const enhance = compose(
  graphql(GET_PLAYER_ID_QUERY, {
    name: 'GetPlayerIdQuery',
    options: ownProps => ({
      variables: {
        region: ownProps.region,
        playerName: ownProps.player
      }
    })
  }),
  graphql(GET_STATS_QUERY, {
    name: 'GetSeasonStatsQuery',
    options: ownProps => ({
      variables: {
        region: ownProps.region,
        playerId: ownProps.GetPlayerIdQuery.playerId,
        season: ownProps.season
      }
    })
  })
);

export default enhance(SeasonStats);

import React, { Component } from 'react';
import { Button } from 'antd';

import { mapsBlurred, mapsPlayerMatches } from '../../KEYS/MAPS';
import { GAME_MODES } from '../../KEYS/GAMEMODES';

import './index.css';
import 'antd/dist/antd.css';

class MatchButton extends Component {
  render() {
    const {
      generalInfo: { date, gameMode, mapName, userRank },
      generalInfo,
      index,
      playersInfo
    } = this.props;

    return (
      <Button
        style={{
          backgroundImage: `url(${mapsPlayerMatches[mapName]})`
        }}
        key={index}
        className="button1"
        data-players={JSON.stringify(playersInfo)}
        data-general={JSON.stringify(generalInfo)}
        onClick={this.props.handleClick}
      >
        <div
          className="blurdiv"
          style={{
            backgroundImage: `url(${mapsBlurred[`${mapName}_blur`]})`
          }}
        />
        <div className="buttonInfoContainer">
          <div className="buttonRank"># {userRank}</div>
          <div className="buttonDate">{date}</div>
          <div className="buttonMode">{GAME_MODES[gameMode]}</div>
        </div>
      </Button>
    );
  }
}
export default MatchButton;

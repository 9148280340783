import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import { auth } from '../../firebase';

import './index.css';

const SignIn = () => {
  return (
    <div className="inputLoginContainer">
      <StyledFirebaseAuth
        uiConfig={{
          signInFlow: 'popup',
          signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
          callbacks: {
            signInSuccessWithAuthResult: () => {
              return false;
            }
          }
        }}
        firebaseAuth={auth}
      />
    </div>
  );
};

export default SignIn;

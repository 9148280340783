import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Spin, Select } from 'antd';

import LeaderBoards from '../../Components/Leaderboards';

import './index.css';
import 'antd/dist/antd.css';

const { Option, OptGroup } = Select;

const GET_LEADERBOARDS_QUERY = gql`
  query GetLeaderboardsQuery(
    $gameMode: String!
    $count: Int!
    $season: String!
  ) {
    leaderboards(gameMode: $gameMode, count: $count, season: $season) {
      name
      rank
      stats {
        averageDamage
        averageRank
        games
        killDeathRatio
        kills
        rankPoints
        winRatio
        wins
      }
    }
  }
`;

class Leaderboards extends Component {
  state = {
    gameMode: 'solo',
    season: 'division.bro.official.pc-2018-06'
  };

  renderLoader = () => {
    return (
      <div className="loading">
        <Spin tip="Fetching Leaderboards" spinning={true} />
      </div>
    );
  };

  changeMode = gameMode => {
    const { season } = this.state;

    this.props.history.replace(`/leaderboards/${season}/${gameMode}`);
  };

  changeSeason = season => {
    const { gameMode } = this.state;

    this.props.history.replace(`/leaderboards/${season}/${gameMode}`);
  };

  renderOptions = () => {
    return (
      <React.Fragment>
        <Select
          onChange={this.changeMode}
          defaultValue="solo"
          style={{ width: 130 }}
        >
          <OptGroup label="Modes">
            <Option value="solo">Solo</Option>
            <Option value="solo-fpp">Solo FPP</Option>
            <Option value="duo">Duo</Option>
            <Option value="duo-fpp">Duo FPP</Option>
            <Option value="squad">Squad</Option>
            <Option value="squad-fpp">Squad FPP</Option>
          </OptGroup>
        </Select>
        <Select
          onChange={this.changeSeason}
          defaultValue="division.bro.official.pc-2018-06"
          style={{ width: 130 }}
        >
          <OptGroup label="Seasons">
            <Option value="division.bro.official.pc-2018-06">Season 6</Option>
            <Option value="division.bro.official.pc-2018-05">Season 5</Option>
            <Option value="division.bro.official.pc-2018-04">Season 4</Option>
            <Option value="division.bro.official.pc-2018-03">Season 3</Option>
            <Option value="division.bro.official.pc-2018-02">Season 2</Option>
            <Option value="division.bro.official.pc-2018-01">Season 1</Option>
          </OptGroup>
        </Select>
      </React.Fragment>
    );
  };

  render() {
    const { leaderboards } = this.props.GetLeaderboardsQuery;

    return (
      <div className="leaderboardContainer">
        <div className="leaderboardsTitle">Leaderboards</div>
        <div className="leaderboardsModes">{this.renderOptions()}</div>
        <div />
        {leaderboards ? (
          leaderboards.length === 0 ? (
            this.renderError()
          ) : (
            <LeaderBoards leaderboards={leaderboards} />
          )
        ) : (
          this.renderLoader()
        )}
      </div>
    );
  }
}

const enhance = compose(
  graphql(GET_LEADERBOARDS_QUERY, {
    name: 'GetLeaderboardsQuery',
    options: ownProps => ({
      variables: {
        season: ownProps.match.params.season,
        gameMode: ownProps.match.params.gameMode,
        count: 500
      }
    })
  })
);

export default withRouter(enhance(Leaderboards));

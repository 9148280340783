import React, { Component } from 'react';
import { Spin } from 'antd';
import { Shape, Stage, Layer } from 'react-konva';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { map } from 'lodash';

import { mapsFull } from '../../KEYS/MAPS';

import 'antd/dist/antd.css';
import './index.css';

const GET_COORDINATES_QUERY = gql`
  query GetCoordinatesQuery($url: String!, $users: [String!], $scale: Int) {
    telemetry(url: $url, users: $users, scale: $scale) {
      playerCoords {
        Id
        Coords {
          x
          y
        }
      }
      safetyZoneCoords {
        x
        y
        radius
      }
      redZoneCoords {
        x
        y
        radius
      }
      blackZoneCoords {
        x
        y
        radius
      }
    }
  }
`;

class TelemetryMap extends Component {
  renderMap = (
    playerCoords,
    redZoneCoords,
    safetyZoneCoords,
    blackZoneCoords
  ) => {
    const { mapName, player } = this.props;

    const locations = playerCoords.filter(obj => {
      return obj.Id === player;
    });

    const coordinates = locations[0].Coords;

    return (
      <div
        className="test"
        style={{ backgroundImage: `url(${mapsFull[`${mapName}_Full`]})` }}
      >
        <Stage width={408} height={408}>
          <Layer>
            <Shape
              fill="#f23113"
              sceneFunc={ctx => {
                ctx.beginPath();
                ctx.strokeStyle = 'red';
                ctx.moveTo(locations[0].Coords[0].x, locations[0].Coords[0].y);

                map(coordinates, item => {
                  if (item.x < 0) {
                    return;
                  } else {
                    ctx.lineTo(item.x, item.y);
                  }
                });

                ctx.stroke();
                ctx.closePath();
                ctx.beginPath();

                ctx.strokeStyle = 'blue';

                map(safetyZoneCoords, coord => {
                  const { radius, x, y } = coord;

                  ctx.moveTo(x + radius, y);
                  ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
                  ctx.stroke();
                });

                ctx.closePath();
                ctx.beginPath();
                ctx.strokeStyle = 'red';

                map(redZoneCoords, coord => {
                  const { radius, x, y } = coord;

                  ctx.moveTo(x + radius, y);
                  ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
                  ctx.stroke();
                });

                ctx.closePath();
                ctx.beginPath();
                ctx.strokeStyle = 'black';

                map(blackZoneCoords, coord => {
                  const { radius, x, y } = coord;

                  ctx.moveTo(x + radius, y);
                  ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
                  ctx.stroke();
                });
              }}
            />
          </Layer>
        </Stage>
      </div>
    );
  };

  renderLoading = () => {
    return (
      <div className="loadingTelemetry">
        <Spin tip="Loading Map..." spinning={true} />
      </div>
    );
  };

  renderError = () => {
    return <div className="loadingTelemetry">No Data for this map</div>;
  };

  render() {
    const { telemetry } = this.props.GetCoordinatesQuery;
    const { mapName } = this.props;
    let playerCoords, redZoneCoords, safetyZoneCoords, blackZoneCoords;

    if (telemetry) {
      playerCoords = telemetry.playerCoords;
      redZoneCoords = telemetry.redZoneCoords;
      safetyZoneCoords = telemetry.safetyZoneCoords;
      blackZoneCoords = telemetry.blackZoneCoords;
    }

    return mapName === 'Range_Main'
      ? this.renderError()
      : playerCoords
      ? this.renderMap(
          playerCoords,
          redZoneCoords,
          safetyZoneCoords,
          blackZoneCoords
        )
      : this.renderLoading();
  }
}

const enhance = compose(
  graphql(GET_COORDINATES_QUERY, {
    name: 'GetCoordinatesQuery',
    options: ownProps => ({
      variables: {
        url: ownProps.url,
        users: ownProps.users,
        scale: ownProps.scale
      }
    })
  })
);

export default enhance(TelemetryMap);

import React, { Component } from 'react';
import { Button } from 'antd';
import { find } from 'lodash';
import { auth } from '../../firebase';

import TelemetryMap from '../TelemetryMap';

import {
  addToFavorites,
  checkIfFavorite,
  removeFromFavorites
} from '../../firebase/db-functions';

import { mapsMatchInfo, maps } from '../../KEYS/MAPS';
import { GAME_MODES } from '../../KEYS/GAMEMODES';
import { SCALE } from '../../KEYS/SCALE';

import car from '../../Assets/car.png';
import clock from '../../Assets/clock.png';
import running from '../../Assets/running.png';

import './index.css';
import 'antd/dist/antd.css';

class MatchInfo extends Component {
  state = {
    playerName: this.props.location.state.playerName,
    userID: null,
    isLoggedIn: false,
    isFav: false
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getUser();
  }

  async getUser() {
    auth.onAuthStateChanged(user => {
      if (user) {
        const userID = user.uid;

        this.setState({ userID, isLoggedIn: true });
      } else {
        // no user
      }
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.userID !== prevState.userID) {
      const { userID } = this.state;
      const { matchId } = this.props.location.state;

      const fav = await checkIfFavorite(userID, matchId);
      if (fav) {
        this.setState({ isFav: true });
      }
    }
  }

  renderMatchOverview = () => {
    const { generalInfo } = this.props.location.state;
    const {
      gameMode,
      mapName,
      date,
      matchDuration,
      participants,
      teams
    } = generalInfo;

    const mode = GAME_MODES[gameMode] ? GAME_MODES[gameMode] : 'Event';

    return (
      <div className="matchOverview">
        <div className="basicInfo">
          {date + ' | ' + maps[mapName] + ' | ' + matchDuration + ' Minutes'}
        </div>
        <div className="fill">
          <img src={mapsMatchInfo[`${mapName}_Small`]} alt="" />
        </div>
        <div className="basicInfo">
          <div className="mode">{mode}</div>
          <div className="players">{participants + ' Players'}</div>
          <div className="teams">{teams + ' Teams'}</div>
        </div>
      </div>
    );
  };

  changeTeammate = value => {
    const playerName = value.target.value;

    this.setState({ playerName });
  };

  Favs = async x => {
    const { value } = x.target;

    const { generalInfo, playersInfo } = this.props.location.state;
    const { userID } = this.state;
    const matchID = generalInfo.matchId;

    if (value === 'remove') {
      removeFromFavorites(userID, matchID);
      this.setState({ isFav: false });
    } else {
      addToFavorites(userID, matchID, generalInfo, playersInfo);
      this.setState({ isFav: true });
    }
  };

  renderStats = () => {
    const { playersInfo, generalInfo } = this.props.location.state;
    const { playerName, isFav } = this.state;

    const {
      assists,
      damage,
      DBNOs,
      heals,
      kills,
      longestKill,
      name,
      rank,
      revives,
      rideDistance,
      timeSurvived,
      walkDistance
    } = find(playersInfo, { name: playerName });

    const { team, telemetryUrl, mapName } = generalInfo;
    const { userID } = this.state;

    return (
      <div className="statsDisplay">
        <div className="teammates">
          <div className="title">{'Team'}</div>
          <div className="team">
            {team.map((value, index) => {
              return (
                <div className="member" key={index}>
                  <Button
                    onClick={this.changeTeammate}
                    className="teammatesButtons buttons"
                    type="primary"
                    value={value}
                  >
                    {value}
                  </Button>
                </div>
              );
            })}
            <Button
              onClick={this.Favs}
              className="switchButton"
              type="primary"
              value={isFav ? 'remove' : 'fav'}
              disabled={userID ? false : true}
            >
              {isFav ? 'Remove From Favs' : 'Add To Favorites'}
            </Button>
          </div>
        </div>
        <div className="allstatsContainer">
          <div className="playername">{name + "'s stats"}</div>
          <div className="rank">{'Rank: ' + rank}</div>
          <div className="elementsContainer">
            <div className="element">
              <div className="elementTitle">Kills</div>
              <div className="elementValue">{kills}</div>
            </div>
            <div className="element">
              <div className="elementTitle">Assists</div>
              <div className="elementValue">{assists}</div>
            </div>
            <div className="element">
              <div className="elementTitle">DBNOs</div>
              <div className="elementValue">{DBNOs}</div>
            </div>
          </div>
          <div className="elementsContainer">
            <div className="element">
              <div className="elementTitle">Heals</div>
              <div className="elementValue">{heals}</div>
            </div>
            <div className="element">
              <div className="elementTitle">Revives</div>
              <div className="elementValue">{revives}</div>
            </div>
            <div className="element">
              <div className="elementTitle">Damage</div>
              <div className="elementValue">{parseInt(damage, 10)}</div>
            </div>
          </div>
          <div className="elementsContainer">
            <div className="element">
              <div className="elementTitle">
                <img src={running} alt="Smiley face" className="images" />
              </div>
              <div className="elementValue">
                {parseInt(walkDistance, 10) + ' m'}
              </div>
            </div>
            <div className="element">
              <div className="elementTitle">Longest Kill</div>
              <div className="elementValue">
                {parseInt(longestKill, 10) + ' m'}
              </div>
            </div>
            <div className="element">
              <div className="elementTitle">
                <img src={car} alt="Smiley face" className="images" />
              </div>
              <div className="elementValue">
                {parseInt(rideDistance, 10) + ' m'}
              </div>
            </div>
          </div>
          <div className="elementsContainer">
            <div className="element">
              <div className="elementTitle">
                <img src={clock} alt="Smiley face" className="images" />
              </div>
              <div className="elementValue">
                {timeSurvived === 1
                  ? timeSurvived + ' minute'
                  : timeSurvived + ' minutes'}
              </div>
            </div>
          </div>
        </div>
        <div className="telemetryMap">
          <TelemetryMap
            users={team}
            player={playerName}
            url={telemetryUrl}
            scale={SCALE[mapName]}
            mapName={mapName}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="parent">
        <div>
          {this.renderMatchOverview()} {this.renderStats()}
        </div>
      </div>
    );
  }
}

export default MatchInfo;

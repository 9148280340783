import React, { Component } from 'react';
import { Progress } from 'antd';
import 'antd/dist/antd.css';

import { getStatus } from '../../Api/ApiCalls';
import './index.css';

class ApiStatus extends Component {
  state = {
    obj: [],
    status: 'active',
    percentage: 0,
    response: '',
    message: '...'
  };

  apiCall = () => {
    getStatus()
      .then(response => {
        const obj = JSON.parse(response);
        this.setState({
          obj,
          response,
          percentage: 100,
          message: 'All Good',
          status: 'success'
        });
      })
      .catch(err => {
        this.setState({ status: 'exception', message: 'API Down' });
        //console.log(err)
      });
  };

  componentWillMount() {
    this.apiCall();
  }

  render() {
    return (
      <div>
        <div className="apiTitle">{'Api Status'}</div>
        {!this.state.spinning && (
          <div className="statusCircle">
            <Progress
              type="circle"
              status={this.state.status}
              percent={this.state.percentage}
              format={() => this.state.message}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ApiStatus;

import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Button } from 'antd';

import './index.css';
import 'antd/dist/antd.css';

class NavBar extends Component {
  render() {
    return (
      <div className="homeButtons">
        <Link to="/">
          <Button className="buttons navBarBtn" type="primary">
            Search
          </Button>
        </Link>
        <Link to="/leaderboards/division.bro.official.pc-2018-06/solo">
          <Button className="buttons navBarBtn" type="primary">
            Leaderboards
          </Button>
        </Link>
        <Link to="/profile">
          <Button className="buttons navBarBtn" type="primary">
            Profile
          </Button>
        </Link>
        <Link to="/news">
          <Button className="buttons navBarBtn" type="primary">
            News
          </Button>
        </Link>
      </div>
    );
  }
}

export default withRouter(NavBar);

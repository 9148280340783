import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { auth } from '../../firebase';

import SignIn from '../SignIn';

import './index.css';
import 'antd/dist/antd.css';

class Header extends Component {
  state = {
    isLoggedIn: false,
    userName: null,
    userImage: null,
    searchInput: null
  };

  async componentDidMount() {
    await this.getUser();
  }

  signOut = () => {
    auth.signOut();
    this.setState({ isLoggedIn: false });
  };

  goToProfile = () => {};

  async getUser() {
    auth.onAuthStateChanged(user => {
      if (user) {
        const userName = user.displayName;
        const userImage = user.photoURL;
        this.setState({ userName, userImage, isLoggedIn: true });
      }
    });
  }

  render() {
    const { isLoggedIn, userName } = this.state;
    return (
      <header className="App-header">
        <div className="name">
          <Link style={{ color: '#f2a900' }} to="/">
            PUBG Records
          </Link>
        </div>
        {isLoggedIn ? (
          <div className="userNameContainer">
            <Link style={{ color: '#f2a900' }} to="/profile">
              Hello, {userName}
            </Link>
            <div onClick={this.signOut} style={{ cursor: 'pointer' }}>
              Sign Out
            </div>
          </div>
        ) : (
          <SignIn />
        )}
      </header>
    );
  }
}

export default withRouter(Header);

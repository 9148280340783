import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';

import SearchPlayer from '../Screens/SearchPlayer';
import NotFound from '../Screens/NotFound';
import Home from '../Screens/Home';
import About from '../Screens/About';
import Privacy from '../Screens/PrivacyPolicy';
import Season from '../Screens/SeasonStats';
import Contact from '../Screens/Contact';
import ApiStatus from '../Screens/ApiStatus';
import Leaderboards from '../Screens/Leaderboards';
import Profile from '../Screens/Profile';
import News from '../Screens/News';
import Article from '../Screens/Article';
import MatchInfo from '../Components/MatchInfo';

import Header from '../Components/Header';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Header />
          <div className="container">
            <NavBar />
            <hr />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                exact
                path="/player/:region/:id"
                render={props => <SearchPlayer {...props} />}
              />
              <Route
                exact
                path="/season/:region/:player/:season"
                render={props => <Season {...props} />}
              />
              <Route
                exact
                path="/news/article/"
                render={props => <Article {...props} />}
              />
              <Route
                exact
                path="/players/games/:matchId"
                render={props => <MatchInfo {...props} />}
              />
              <Route
                exact
                path="/leaderboards/:season/:gameMode"
                component={Leaderboards}
              />
              <Route exact path="/about" component={About} />
              <Route exact path="/status" component={ApiStatus} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/news" component={News} />
              <Route exact path="/Privacy" component={Privacy} />
              <Route exact path="/profile" component={Profile} />
              <Route path="*" component={NotFound} />
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Icon } from 'antd';

import './index.css';
import 'antd/dist/antd.css';

const Footer = () => (
  <footer className={'App-footer'}>
    <div className="Items">
      <div className="footerItems">
        <Link style={{ color: '#f2a900' }} to="/about">
          About
        </Link>
      </div>
      <div className="footerItems">
        <Link style={{ color: '#f2a900' }} to="/contact">
          Contact
        </Link>
      </div>
      <div className="footerItems">
        <Link style={{ color: '#f2a900' }} to="/Privacy">
          Privacy Policy
        </Link>
      </div>
      <div className="footerItems">
        <Link style={{ color: '#f2a900' }} to="/status">
          API Status
        </Link>
      </div>
      <div className={'footer-text'}>
        {'Jad Termsani '}
        <Icon type="copyright" style={{ color: 'f2a900' }} />
        {' 2020'}
      </div>
    </div>
  </footer>
);

export default withRouter(Footer);

import React, { Component } from 'react';
import { Card, Button } from 'antd';
import profile from '../../Assets/profile.png';

import './index.css';
import 'antd/dist/antd.css';

const { Meta } = Card;

class Contact extends Component {
  render() {
    return (
      <div>
        <h1> Contact </h1>
        <div className="about1">
          {
            <p>
              I am a Full Stack Engineer at
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://postlight.com/"
              >
                {' '}
                Postlight
              </a>
            </p>
          }
        </div>

        <Card
          hoverable
          className="card"
          cover={<img alt="example" src={profile} />}
        >
          <Meta title="Jad Termsani" description="JadT26" />
        </Card>
        <div className="about2">
          {<p>This website uses the official PUBG API</p>}
        </div>
        <div className="dots">
          <h1> ... </h1>
        </div>
        <div className="communicate">
          <h2> Want to communicate?</h2>
        </div>
        <div>
          <Button
            href="https://twitter.com/JadTermsani"
            type="primary"
            icon="twitter"
            style={{ 'margin-right': '5px' }}
          />
          <Button
            href="https://www.facebook.com/Jad26"
            type="primary"
            icon="facebook"
            style={{ 'margin-right': '5px' }}
          />
          <Button
            href="https://www.instagram.com/jadtermsani/"
            type="primary"
            icon="instagram"
            style={{ 'margin-right': '5px' }}
          />
          <Button
            href="https://www.youtube.com/channel/UCu0U9ZWIQZs7MWIhqDOjeJA"
            icon="youtube"
            type="primary"
          />
        </div>
      </div>
    );
  }
}

export default Contact;

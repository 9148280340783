import React, { Component } from 'react';
import { BackTop, Spin, Select } from 'antd';
import { map } from 'lodash';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { addSearchedUser, addAllGames } from '../../firebase/db-functions';

import 'antd/dist/antd.css';
import './index.css';

import MatchButton from '../../Components/MatchButton';
import MatchInfo from '../../Components/MatchInfo';

import { maps } from '../../KEYS/MAPS';
import { GAME_MODES } from '../../KEYS/GAMEMODES';

const MAP_OPTIONS = [
  'Erangel',
  'Miramar',
  'Sanhok',
  'Vikendi',
  'Training',
  'Karakin'
];
const MODE_OPTIONS = [
  'Solo FPP',
  'Solo TPP',
  'Duo FPP',
  'Duo TPP',
  'Squad FPP',
  'Squad TPP'
];

const GET_MATCHES_QUERY = gql`
  query GetMatchesQuery($region: String!, $playerName: String!) {
    playerGames(region: $region, playerName: $playerName) {
      id
    }
  }
`;

const GET_PLAYER_ID_QUERY = gql`
  query GetMatchesQuery($region: String!, $playerName: String!) {
    playerId(region: $region, playerName: $playerName)
  }
`;

const GET_DETAILS_QUERY = gql`
  query GetDetailsQuery(
    $region: String!
    $matchesId: [String!]!
    $playerId: String!
  ) {
    matchesInfo(region: $region, matchesId: $matchesId, playerId: $playerId) {
      playersInfo {
        assists
        damage
        DBNOs
        headshotKills
        heals
        killPlace
        kills
        longestKill
        name
        playerId
        rank
        revives
        rideDistance
        roadKills
        swimDistance
        teamKills
        timeSurvived
        vehiclesDestroyed
        walkDistance
      }
      generalInfo {
        date
        gameMode
        mapName
        matchDuration
        matchId
        participants
        teams
        time
        userRank
        team
        telemetryUrl
      }
    }
  }
`;

class SearchPlayer extends Component {
  state = {
    matchesInfo: [],
    bool: false,
    generalInfo: '',
    playersInfo: '',
    selectedMaps: [],
    selectedModes: [],
    showGames: true
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (state.matchesInfo.length === 0) {
      return {
        matchesInfo: nextProps.GetDetailsQuery.matchesInfo || []
      };
    }
    return null;
  }

  renderLoading = () => {
    return (
      <div className="loading">
        <Spin tip="Loading..." spinning={this.state.spinning} />
      </div>
    );
  };

  handleClick = x => {
    const { players, general } = x.currentTarget.dataset;
    const { matchId } = JSON.parse(general);

    this.props.history.push(`/players/games/${matchId}`, {
      generalInfo: JSON.parse(general),
      playersInfo: JSON.parse(players),
      playerName: this.props.match.params.id,
      matchId: matchId
    });
  };

  handleMapFilter = selectedMaps => {
    this.setState({ selectedMaps });
  };

  handleModeFilter = selectedModes => {
    this.setState({ selectedModes });
  };

  renderErrorNoUser = () => {
    return (
      <div className="error">Sorry, username does not exist ¯\\_(ツ)_/¯</div>
    );
  };

  renderMatchInfo = (playersInfo, generalInfo) => {
    const matchId = JSON.parse(generalInfo).matchId;
    return (
      <MatchInfo
        playersInfo={JSON.parse(playersInfo)}
        generalInfo={JSON.parse(generalInfo)}
        playerName={this.props.match.params.id}
        matchId={matchId}
      />
    );
  };

  renderErrorFilter = () => {
    return <div>No games match the selected filters</div>;
  };

  renderErrorNoGames = () => {
    return <div>User has not played any game during the last 2 weeks</div>;
  };

  renderFilter = () => {
    const { selectedMaps, selectedModes } = this.state;
    const filteredMapOptions = MAP_OPTIONS.filter(
      o => !selectedMaps.includes(o)
    );
    const filteredModeOptions = MODE_OPTIONS.filter(
      o => !selectedModes.includes(o)
    );

    return (
      <div className="filtersContainer">
        <div className="mapFilter">
          <Select
            mode="multiple"
            placeholder="Filter Maps"
            value={selectedMaps}
            onChange={this.handleMapFilter}
            style={{ width: '100%' }}
            allowClear
          >
            {filteredMapOptions.map(item => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="modeFilter">
          <Select
            mode="multiple"
            placeholder="Filter Modes"
            value={selectedModes}
            onChange={this.handleModeFilter}
            style={{ width: '100%' }}
            allowClear
          >
            {filteredModeOptions.map(item => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    );
  };

  addToAllMatches = (name, matchesInfo) => {
    map(matchesInfo, value => {
      const { matchId } = value.generalInfo;
      const { generalInfo, playersInfo } = value;
      addAllGames(name, matchId, generalInfo, playersInfo);
    });
  };

  renderMatches() {
    const { matchesInfo, selectedMaps, selectedModes, showGames } = this.state;
    const { id } = this.props.match.params;
    const matches = matchesInfo.length;
    const mapsLength = selectedMaps.length;
    const modesLength = selectedModes.length;

    addSearchedUser(id, true);
    this.addToAllMatches(id, matchesInfo);

    const filtered = matchesInfo.filter(obj => {
      if (mapsLength > 0 && modesLength > 0) {
        return (
          selectedMaps.includes(maps[obj.generalInfo.mapName]) &&
          selectedModes.includes(GAME_MODES[obj.generalInfo.gameMode])
        );
      } else if (mapsLength > 0 && modesLength === 0) {
        return selectedMaps.includes(maps[obj.generalInfo.mapName]);
      } else {
        return selectedModes.includes(GAME_MODES[obj.generalInfo.gameMode]);
      }
    });

    return (
      <div className="AllMatchContainer">
        <div className="infoContainer">
          <div className="info">
            {matches + ' games during the last 2 weeks'}
          </div>
          {this.renderFilter()}
        </div>
        <div className="AllRows">
          {showGames
            ? map(
                filtered.length > 0 ? filtered : matchesInfo,
                (value, index) => {
                  const { generalInfo, playersInfo } = value;
                  return (
                    <MatchButton
                      index={index}
                      generalInfo={generalInfo}
                      handleClick={this.handleClick}
                      playersInfo={playersInfo}
                      key={index}
                    />
                  );
                }
              )
            : this.renderError()}
        </div>
      </div>
    );
  }

  renderAllGames = () => {
    const { matchesInfo } = this.state;
    const { error } = this.props.GetPlayerIdQuery;

    const matchInfo = this.props.GetDetailsQuery.matchesInfo;

    return (
      <div>
        <div className="font">{this.props.match.params.id}</div>
        {error
          ? this.renderErrorNoUser()
          : matchesInfo.length > 0
          ? this.renderMatches()
          : matchInfo
          ? matchInfo.length === 0
            ? this.renderErrorNoGames()
            : this.renderLoading()
          : this.renderLoading()}
        <BackTop />
      </div>
    );
  };

  render() {
    const { bool, playersInfo, generalInfo } = this.state;

    return (
      <div>
        {!bool
          ? this.renderAllGames()
          : generalInfo && this.renderMatchInfo(playersInfo, generalInfo)}
      </div>
    );
  }
}

const enhance = compose(
  graphql(GET_PLAYER_ID_QUERY, {
    name: 'GetPlayerIdQuery',
    options: ownProps => ({
      variables: {
        region: ownProps.match.params.region,
        playerName: ownProps.match.params.id
      }
    })
  }),
  graphql(GET_MATCHES_QUERY, {
    name: 'GetMatchesQuery',
    options: ownProps => ({
      variables: {
        region: ownProps.match.params.region,
        playerName: ownProps.match.params.id
      }
    })
  }),
  graphql(GET_DETAILS_QUERY, {
    name: 'GetDetailsQuery',
    options: ownProps => ({
      variables: {
        region: ownProps.match.params.region,
        matchesId:
          ownProps.GetMatchesQuery.playerGames &&
          ownProps.GetMatchesQuery.playerGames.map(({ id }) => id),
        playerId: ownProps.GetPlayerIdQuery.playerId
      }
    })
  })
);

export default enhance(SearchPlayer);

import React, { Component } from 'react';
import { Select, Input } from 'antd';
import { addSearchedUser } from '../../firebase/db-functions';

import SeasonStats from '../../Components/SeasonStats';
import { getSeasons } from '../../firebase/db-functions';

import 'antd/dist/antd.css';
import './index.css';

const { Option, OptGroup } = Select;
const Search = Input.Search;

const options = (
  <OptGroup label="Platform">
    <Option value="steam">Steam</Option>
    <Option value="xbox">Xbox</Option>
    <Option value="psn">Psn</Option>
    <Option value="kakao">Kakao</Option>
  </OptGroup>
);

class SeasonsHome extends Component {
  state = {
    visibility: 'hidden',
    visibility2: 'hidden',
    statsLeft: false,
    statsRight: false,
    btnleft: 'visible',
    btnright: 'visible',
    playerLeft: '',
    seasonLeft: this.props.match.params.season,
    regionLeft: this.props.match.params.region,
    playerRight: '',
    seasonRight: this.props.match.params.season,
    regionRight: this.props.match.params.region,
    seasonMid: this.props.match.params.season,
    seasons: null,
    currentSeason: ''
  };

  async componentDidMount() {
    const { seasons, currentSeason } = await getSeasons();
    this.setState({ seasons: seasons.reverse(), currentSeason })
  }

  switch = () => {
    this.setState({
      btnleft: 'hidden'
    });
  };

  switch1 = () => {
    this.setState({
      btnright: 'hidden'
    });
  };

  searchLeft = name => {
    this.setState({
      statsLeft: true,
      playerLeft: name
    });
  };

  searchRight = name => {
    this.setState({ statsRight: true, playerRight: name });
  };

  onchangeSeasonLeft = season => {
    this.setState({
      seasonLeft: season
    });
  };

  onchangeSeasonRight = season => {
    this.setState({
      seasonRight: season
    });
  };

  onchangeSeasonMid = season => {
    this.setState({
      seasonMid: season
    });
  };

  restartRight = () => {
    this.setState({
      statsRight: false
    });
  };

  restartLeft = () => {
    this.setState({
      statsLeft: false
    });
  };

  onchange = regionLeft => {
    this.setState({ regionLeft });
  };

  onchange2 = regionRight => {
    this.setState({ regionRight });
  };

  renderSeasonStats = () => {
    const { player } = this.props.match.params;
    const { seasons, currentSeason } = this.state;

    addSearchedUser(player, true);

    const optValues = seasons ? seasons.map((season, index) => {
      return (<Option key={index} value={season.ID}>
        {season.Name}
    </Option>)
    }) : [];

    const activeSeason = (
      <OptGroup label="Active Season">
        <Option value={currentSeason.ID}>{currentSeason.Name}</Option>
      </OptGroup>
    );
    
    const seasonOptions = (
      <OptGroup label="Past Seasons">
        {optValues}
      </OptGroup>
    );

    const Regions = (
      <Select
        placeholder="Platform"
        onChange={this.onchange}
        style={{ width: 90 }}
      >
        {options}
      </Select>
    );
    const Regions2 = (
      <Select
        placeholder="Platform"
        onChange={this.onchange2}
        style={{ width: 90 }}
      >
        {options}
      </Select>
    );

    return (
      <div>
        <div className="mobileTitle">
          <div className="seasonTitle"> Season Stats </div>
        </div>
        <div className="mainContainer">
          <div className="leftContainer">
            {
              <div>
                <div className="seasonSelect1">
                  <div className="inputLeft">
                    <Search
                      addonBefore={Regions}
                      style={{ width: '300px' }}
                      placeholder="PUBG Username"
                      onSearch={this.searchLeft}
                      enterButton="Search"
                    />
                  </div>
                  <div className="seasonboxLeft">
                    <div className="seasonText">Select a season: </div>
                    <Select
                      onChange={this.onchangeSeasonLeft}
                      defaultValue={this.state.seasonLeft}
                      style={{
                        position: 'relative',
                        width: '130px',
                        left: '-25px'
                      }}
                    >
                      {activeSeason}
                      {seasonOptions}
                    </Select>
                  </div>
                </div>
                {this.state.playerLeft && (
                  <div className="stats">
                    <SeasonStats
                      season={this.state.seasonLeft}
                      player={this.state.playerLeft}
                      region={this.state.regionLeft}
                    />
                  </div>
                )}
              </div>
            }
          </div>
          <div className="midContainer">
            <div className="seasonMid">
              <div className="seasonText">Select a season: </div>
              <Select
                onChange={this.onchangeSeasonMid}
                defaultValue={this.state.seasonRight}
                style={{
                  position: 'relative',
                  width: '130px',
                  left: '-25px'
                }}
              >
                {activeSeason}
                {seasonOptions}
              </Select>
            </div>
            <div className="stats1">
              <SeasonStats
                season={this.state.seasonMid}
                player={this.props.match.params.player}
                region={this.props.match.params.region}
              />
            </div>
          </div>
          <div className="rightContainer">
            <div>
              <div className="seasonSelect1">
                <div className="inputRight">
                  <Search
                    addonBefore={Regions2}
                    style={{ width: '300px' }}
                    placeholder="PUBG Username"
                    onSearch={this.searchRight}
                    enterButton="Search"
                  />
                </div>
                <div className="seasonboxRight">
                  <div className="seasonText">Select a season: </div>
                  <Select
                    onChange={this.onchangeSeasonRight}
                    defaultValue={this.state.seasonRight}
                    style={{
                      position: 'relative',
                      width: '130px',
                      left: '-25px'
                    }}
                  >
                    {activeSeason}
                    {seasonOptions}
                  </Select>
                </div>
              </div>
              {this.state.playerRight && (
                <div className="stats2">
                  <SeasonStats
                    season={this.state.seasonRight}
                    player={this.state.playerRight}
                    region={this.state.regionRight}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.renderSeasonStats();
  }
}

export default SeasonsHome;

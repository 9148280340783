import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { auth } from '../../firebase';

import openNotificationWithIcon from '../../Components/Notifications';
import FavoritesContainer from '../../Components/Profile/FavoriteGames';
import LifetimeStats from '../../Components/Profile/LifetimeStats';
import WeaponMastery from '../../Components/Profile/WeaponMastery';

import {
  getFavorites,
  getUsername,
  addUsername
} from '../../firebase/db-functions';

import './index.css';
import 'antd/dist/antd.css';

import { Button, Input } from 'antd';

const Search = Input.Search;

const failure = {
  type: 'warning',
  message: 'Please Sign In To View Your Profile',
  description: ''
};

class Login extends Component {
  state = {
    isLoggedIn: false,
    userName: null,
    userImage: null,
    userID: null,
    loading: true,
    PUBGUSERNAME: null,
    switchUsername: true,
    favs: null
  };

  async componentDidMount() {
    await this.getUser();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.userName !== prevState.userName) {
      const { userID, userName } = this.state;

      const favs = await this.getFavs(userName);
      const u = await getUsername(userID);

      this.setState({
        loading: false,
        favs,
        PUBGUSERNAME: u ? u.username : null
      });
    }
  }

  redirect = page => {
    this.props.history.push(`/${page}`);
  };

  async getUser() {
    auth.onAuthStateChanged(user => {
      if (user) {
        const { displayName, photoURL, uid } = user;

        this.setState({
          userName: displayName,
          userImage: photoURL,
          userID: uid,
          isLoggedIn: true
        });
      } else {
        openNotificationWithIcon(failure);
        this.redirect('');
      }
    });
  }

  handleClick = x => {
    const { players, general } = x.currentTarget.dataset;
    const parsedGeneral = JSON.parse(general);

    const playerName = parsedGeneral.team[0];
    const { matchId } = parsedGeneral;

    this.props.history.push(`/players/games/${matchId}`, {
      generalInfo: JSON.parse(general),
      playersInfo: JSON.parse(players),
      playerName: playerName,
      matchId: matchId
    });
  };

  getFavs = async () => {
    const { userID } = this.state;

    return await getFavorites(userID);
  };

  addUsername = username => {
    const { userID } = this.state;

    this.setState({ PUBGUSERNAME: username });
    addUsername(userID, username);
  };

  switchUsername = () => {
    const { switchUsername } = this.state;
    this.setState({ switchUsername: !switchUsername });
  };

  renderImageContainer = (userName, userImage) => {
    const { switchUsername, PUBGUSERNAME } = this.state;

    return (
      <div className="profileImageContainer">
        <div className="ImageContainer">
          <img className="profileImage" src={userImage} alt="profile" />
          <div className="profileNameContainer">
            <div className="profileuserName">{userName}</div>
            {!PUBGUSERNAME ? (
              switchUsername ? (
                <Button
                  className="buttons navBarBtn"
                  type="primary"
                  onClick={this.switchUsername}
                >
                  Add Username
                </Button>
              ) : (
                <Search
                  placeholder="Your PUBG Username"
                  enterButton="Add"
                  onSearch={this.addUsername}
                />
              )
            ) : (
              <div className="profileUserName">
                <Link
                  style={{ color: '#f2a900' }}
                  to={`/player/steam/${PUBGUSERNAME}`}
                >
                  {PUBGUSERNAME}
                </Link>
              </div>
            )}

            {/* /player/steam/JadT26 */}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { userName, userImage, favs, PUBGUSERNAME } = this.state;

    return (
      <div>
        <div className="profileContainer">
          {this.renderImageContainer(userName, userImage)}
          {PUBGUSERNAME && <LifetimeStats PUBGUSERNAME={PUBGUSERNAME} />}
          {PUBGUSERNAME && <WeaponMastery PUBGUSERNAME={PUBGUSERNAME} />}
          {favs && (
            <FavoritesContainer handleClick={this.handleClick} favs={favs} />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Login);

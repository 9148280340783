import { notification } from 'antd';

import 'antd/dist/antd.css';
import './index.css';

export default function openNotificationWithIcon(obj) {
  notification[obj.type]({
    message: obj.message,
    description: obj.description,
    duration: 3
  });
}

import React, { Component } from 'react';

import './index.css';

class Privacy extends Component {
  render() {
    return (
      <div className="privacy">
        <h1>Privacy policy</h1>

        <p>
          This privacy policy ("Policy") describes how Website Operator
          ("Website Operator", "we", "us" or "our") collects, protects and uses
          the personally identifiable information ("Personal Information") you
          ("User", "you" or "your") may provide on the website and any of its
          products or services (collectively, "Website" or "Services"). It also
          describes the choices available to you regarding our use of your
          personal information and how you can access and update this
          information. This Policy does not apply to the practices of companies
          that we do not own or control, or to individuals that we do not employ
          or manage.
        </p>

        <h2>Collection of personal information</h2>

        <p>
          We receive and store any information you knowingly provide to us when
          you fill any online forms on the Website. You can choose not to
          provide us with certain information, but then you may not be able to
          take advantage of some of the Website's features.
        </p>

        <h2>Collection of non-personal information</h2>

        <p>
          When you visit the Website our servers automatically record
          information that your browser sends. This data may include information
          such as your device's IP address, browser type and version, operating
          system type and version, language preferences or the webpage you were
          visiting before you came to our Website, pages of our Website that you
          visit, the time spent on those pages, information you search for on
          our Website, access times and dates, and other statistics.
        </p>

        <h2>Use of collected information</h2>

        <p>
          Any of the information we collect from you may be used to Non-personal
          information collected is used only to identify potential cases of
          abuse and establish statistical information regarding Website usage.
          This statistical information is not otherwise aggregated in such a way
          that would identify any particular user of the system.
        </p>

        <h2>Children</h2>

        <p>
          We do not knowingly collect any personal information from children
          under the age of 13. If you are under the age of 13, please do not
          submit any personal information through our Website or Service. We
          encourage parents and legal guardians to monitor their children's
          Internet usage and to help enforce this Policy by instructing their
          children never to provide personal information through our Website or
          Service without their permission. If you have reason to believe that a
          child under the age of 13 has provided personal information to us
          through our Website or Service, please contact us.
        </p>

        <h2>Advertisement</h2>

        <p>
          We may display online advertisements and we may share aggregated and
          non-identifying information about our customers that we collect
          through the registration process or through online surveys and
          promotions with certain advertisers. We do not share personally
          identifiable information about individual customers with advertisers.
          In some instances, we may use this aggregated and non-identifying
          information to deliver tailored advertisements to the intended
          audience.
        </p>

        <h2>Links to other websites</h2>

        <p>
          Our Website contains links to other websites that are not owned or
          controlled by us. Please be aware that we are not responsible for the
          privacy practices of such other websites or third-parties. We
          encourage you to be aware when you leave our Website and to read the
          privacy statements of each and every website that may collect personal
          information.
        </p>

        <h2>Information security</h2>

        <p>
          We secure information you provide on computer servers in a controlled,
          secure environment, protected from unauthorized access, use, or
          disclosure. We maintain reasonable administrative, technical, and
          physical safeguards in an effort to protect against unauthorized
          access, use, modification, and disclosure of personal information in
          its control and custody. However, no data transmission over the
          Internet or wireless network can be guaranteed. Therefore, while we
          strive to protect your personal information, you acknowledge that (i)
          there are security and privacy limitations of the Internet which are
          beyond our control; (ii) the security, integrity, and privacy of any
          and all information and data exchanged between you and our Website
          cannot be guaranteed; and (iii) any such information and data may be
          viewed or tampered with in transit by a third-party, despite best
          efforts.
        </p>

        <h2>Data breach</h2>

        <p>
          In the event we become aware that the security of the Website has been
          compromised or users Personal Information has been disclosed to
          unrelated third-parties as a result of external activity, including,
          but not limited to, security attacks or fraud, we reserve the right to
          take reasonably appropriate measures, including, but not limited to,
          investigation and reporting, as well as notification to and
          cooperation with law enforcement authorities. In the event of a data
          breach, we will make reasonable efforts to notify affected individuals
          if we believe that there is a reasonable risk of harm to the user as a
          result of the breach or if notice is otherwise required by law. When
          we do we will post a notice on the Website.
        </p>

        <h2>Changes and amendments</h2>

        <p>
          We reserve the right to modify this privacy policy relating to the
          Website or Services at any time, effective upon posting of an updated
          version of this Policy on the Website. When we do we will post a
          notification on the main page of our Website. Continued use of the
          Website after any such changes shall constitute your consent to such
          changes. Policy was created with WebsitePolicies.com
        </p>

        <h2>Acceptance of this policy</h2>

        <p>
          You acknowledge that you have read this Policy and agree to all its
          terms and conditions. By using the Website or its Services you agree
          to be bound by this Policy. If you do not agree to abide by the terms
          of this Policy, you are not authorized to use or access the Website
          and its Services.
        </p>

        <h2>Contacting us</h2>

        <p>If you have any questions about this Policy, please contact us.</p>

        <p>This document was last updated on April 27, 2018</p>
      </div>
    );
  }
}

export default Privacy;

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import './index.css';
import 'antd/dist/antd.css';
import { label } from 'react-konva';

const GET_PLAYER_ID_QUERY = gql`
  query getPlayerId($region: String!, $playerName: String!) {
    playerId(region: $region, playerName: $playerName)
  }
`;

const GET_LIFETIMESTATS_QUERY = gql`
  query getLifetimeStats($region: String!, $playerId: String!) {
    getLifetimeStats(region: $region, playerId: $playerId) {
      assists
      damage
      dBNOs
      deaths
      drivingDistance
      headshotKills
      heals
      kdRatio
      kills
      longestGame
      longestKill
      revives
      roadKills
      roundMostKills
      rounds
      runningDistance
      suicides
      swimDistance
      teamKills
      timePlayed
      top10s
      vehiclesDestroyed
      weaponsAcquired
      wins
    }
  }
`;

class LifetimeStats extends Component {
  renderLifetimeStats = stats => {
    const {
      assists,
      damage,
      dBNOs,
      drivingDistance,
      headshotKills,
      heals,
      kdRatio,
      kills,
      longestKill,
      revives,
      roadKills,
      roundMostKills,
      rounds,
      runningDistance,
      suicides,
      swimDistance,
      teamKills,
      top10s,
      vehiclesDestroyed,
      weaponsAcquired,
      wins
    } = stats;

    return (
      <React.Fragment>
        <div className="statsContainer">
          <header>General Stats</header>
          <label>Kills: {kills}</label>
          <label>Assists: {assists}</label>
          <label>dBNOs: {dBNOs}</label>
          <label>Rounds: {rounds}</label>
          <label>Top10s: {top10s}</label>
          <label>Wins: {wins}</label>
        </div>
        <div className="statsContainer">
          <header>Mobility Stats</header>
          <label>
            Running: {parseFloat(runningDistance / 1000).toFixed(2)} Km
          </label>
          <label>
            Driving: {parseFloat(drivingDistance / 1000).toFixed(2)} Km
          </label>
          <label>
            Swimming: {parseFloat(swimDistance / 1000).toFixed(2)} Km
          </label>
        </div>
        <div className="statsContainer">
          <header>Misc Stats</header>
          <label>Heals: {heals}</label>
          <label>K/D: {kdRatio}</label>
          <label>Weapons: {weaponsAcquired}</label>
          <label>Suicides: {suicides}</label>
          <label>Revives: {revives}</label>
          <label>Team Kills: {teamKills}</label>
        </div>
        <div className="statsContainer">
          <header>Combat Stats</header>
          <label>Headshot Kills: {headshotKills}</label>
          <label>Longest Kill: {longestKill} m</label>
          <label>Road Kills: {roadKills}</label>
          <label>Damage: {damage}</label>
          <label>Vehicles Destroyed: {vehiclesDestroyed}</label>
          <label>Most Kills: {roundMostKills}</label>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const {
      getLifetimeStats: { getLifetimeStats },
      PUBGUSERNAME
    } = this.props;

    return (
      <div>
        <div className="lifetimeTitle">Lifetime Stats</div>
        <div className="lifetimeContainer">
          {PUBGUSERNAME ? (
            getLifetimeStats ? (
              this.renderLifetimeStats(getLifetimeStats)
            ) : (
              <div>Loading ...</div>
            )
          ) : (
            <div>Please enter your PUBG Username for your stats to show up</div>
          )}
        </div>
      </div>
    );
  }
}

const enhance = compose(
  graphql(GET_PLAYER_ID_QUERY, {
    name: 'getPlayerId',
    options: ownProps => ({
      variables: {
        region: 'steam',
        playerName: ownProps.PUBGUSERNAME
      }
    })
  }),
  graphql(GET_LIFETIMESTATS_QUERY, {
    name: 'getLifetimeStats',
    options: ownProps => ({
      variables: {
        region: 'steam',
        playerId: ownProps.getPlayerId.playerId
          ? ownProps.getPlayerId.playerId
          : ''
      }
    })
  })
);

export default withRouter(enhance(LifetimeStats));

import React, { Component } from 'react';
import { Table } from 'antd';

import './index.css';
import 'antd/dist/antd.css';

const columns = [
  {
    title: 'Rank',
    dataIndex: 'rank'
  },
  {
    title: 'Name',
    dataIndex: 'name'
  },
  {
    title: 'Games',
    dataIndex: 'stats.games'
  },
  {
    title: 'Wins',
    dataIndex: 'stats.wins'
  },
  {
    title: 'Win Ratio',
    dataIndex: 'stats.winRatio'
  },
  {
    title: 'Kills',
    dataIndex: 'stats.kills'
  },
  {
    title: 'K/D',
    dataIndex: 'stats.killDeathRatio'
  },
  {
    title: 'Rank Points',
    dataIndex: 'stats.rankPoints'
  }
];

class Leaderboards extends Component {
  renderTable = leaderboards => {
    return (
      <Table
        columns={columns}
        dataSource={leaderboards}
        pagination={{ pageSize: 10 }}
      />
    );
  };

  render() {
    const { leaderboards } = this.props;

    return this.renderTable(leaderboards);
  }
}

export default Leaderboards;

import firebase from 'firebase/app';
import { db } from './index';
import { map } from 'lodash';

export const addSearchedUser = async (user, status) => {
  await db
    .collection('searchedUsers')
    .doc(user)
    .set(
      {
        search: status,
        season: status
      },
      { merge: true }
    );
};

export const addToFavorites = async (
  userID,
  matchID,
  generalInfo,
  playersInfo
) => {
  const obj = {};

  obj[matchID] = {
    playersInfo: playersInfo,
    generalInfo: generalInfo
  };

  await db
    .collection('Favorites')
    .doc(userID)
    .set(obj, { merge: true });
};

export const getFavorites = async userID => {
  return db
    .collection('Favorites')
    .doc(userID)
    .get()
    .then(res => res.data());
};

export const addAllGames = async (name, matchId, generalInfo, playersInfo) => {
  const obj = {};

  obj[matchId] = {
    playersInfo: playersInfo,
    generalInfo: generalInfo
  };

  await db
    .collection('AllGames')
    .doc(name)
    .set(obj, { merge: true });
};

export const checkIfFavorite = async (userID, ID) => {
  const favs = await getFavorites(userID);

  let fav = false;

  map(favs, value => {
    const { matchId } = value.generalInfo;
    if (matchId === ID) {
      fav = true;
      return true;
    }
  });

  return fav;
};

export const removeFromFavorites = async (userID, matchId) => {
  await db
    .collection('Favorites')
    .doc(userID)
    .update({
      [`${matchId}`]: firebase.firestore.FieldValue.delete()
    });
};

export const getUsername = async userID => {
  return db
    .collection('Usernames')
    .doc(userID)
    .get()
    .then(res => res.data());
};

export const addUsername = async (userID, username) => {
  return db
    .collection('Usernames')
    .doc(userID)
    .set({ username: username }, { merge: true });
};

export const getSeasons = async () => {
  return db
    .collection('PUBG-API')
    .doc('seasons')
    .get()
    .then(res => res.data());
};

export const getWeapons = async () => {
  return db
    .collection('PUBG-API')
    .doc('weapons')
    .get()
    .then(res => res.data());
};

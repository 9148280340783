import axios from 'axios';
import { apiStatus } from '../KEYS/CONSTANTS';

export function getStatus() {
  return axios
    .get(apiStatus, {
      headers: {
        Accept: 'application/vnd.api+json'
      }
    })
    .then(response => {
      return JSON.stringify(response);
    });
}

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var config = {
  apiKey: 'AIzaSyD3cwfrgQH4pfZDil9cNEFIEPzs83xfdoo',
  authDomain: 'pubgrecords-ae725.firebaseapp.com',
  databaseURL: 'https://pubgrecords-ae725.firebaseio.com',
  projectId: 'pubgrecords-ae725',
  storageBucket: 'pubgrecords-ae725.appspot.com',
  messagingSenderId: '665734745414'
};

firebase.initializeApp(config);

const db = firebase.firestore();
const auth = firebase.auth();

export { db, auth };

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { map } from 'lodash';

import MatchButton from '../../MatchButton';

import './index.css';
import 'antd/dist/antd.css';

class Favorites extends Component {
  state = {
    matchInfo: false
  };

  render() {
    const { favs } = this.props;
    return (
      <div className="FavoritesContainer">
        <div className="FavoritesTitle">Favorite Games</div>
        {favs ? (
          <div className="FavoriteGames">
            {map(favs, (value, index) => {
              const { generalInfo, playersInfo } = value;
              return (
                <MatchButton
                  index={index}
                  generalInfo={generalInfo}
                  handleClick={this.props.handleClick}
                  playersInfo={playersInfo}
                  key={index}
                />
              );
            })}
          </div>
        ) : (
          <div> You dont have any games marked as favorites </div>
        )}
      </div>
    );
  }
}

export default withRouter(Favorites);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Button } from 'antd';
import { map, includes } from 'lodash';

import { getWeapons } from '../../../firebase/db-functions';

import './index.css';
import 'antd/dist/antd.css';

const GET_PLAYER_ID_QUERY = gql`
  query getPlayerId($region: String!, $playerName: String!) {
    playerId(region: $region, playerName: $playerName)
  }
`;

const GET_WEAPONMASTERY_QUERY = gql`
  query weaponMastery($region: String!, $playerId: String!) {
    weaponMastery(region: $region, playerId: $playerId) {
      name
      stats {
        damage
        defeats
        headshots
        kills
        knocks
        levelCurrent
        longRangeKills
        longestKill
        roundMostDamage
        roundMostDefeats
        roundMostHeadshots
        roundMostKills
        roundMostKnocks
        tierCurrent
        xpTotal
      }
      medals {
        count
        medalId
      }
    }
  }
`;

class WeaponMastery extends Component {
  state = {
    weapons: {},
    weaponClass: 'AR'
  };

  renderWeaponMastery = weaponMastery => {
    const { weapons, weaponClass } = this.state;
    let fixedNames = [];

    map(weapons[weaponClass], (wep, key) => {
      fixedNames.push(key);
    });

    const renderedMastery = map(weaponMastery, (weapon, index) => {
      const { name, stats } = weapon;
      const {
        damage,
        headshots,
        kills,
        knocks,
        levelCurrent,
        longRangeKills,
        longestKill,
        roundMostDamage,
        roundMostHeadshots,
        roundMostKills,
        roundMostKnocks,
        tierCurrent,
        xpTotal
      } = stats;

      if (includes(fixedNames, name)) {
        return (
          <div className="masteryContainer" key={index}>
            <div className="weaponInfoContainer">
              <div className="weaponInfo">
                <div className="weaponTitle">
                  {weapons[weaponClass][name] &&
                    weapons[weaponClass][name].name}
                </div>
                <div className="weaponLvl">
                  <label>Tier: {tierCurrent}</label>
                  <label>Level: {levelCurrent}</label>
                </div>
              </div>
            </div>
            <div className="weaponImage">
              <img
                className="weaponImage"
                src={
                  weapons[weaponClass][name] && weapons[weaponClass][name].image
                }
                alt={
                  weapons[weaponClass][name] && weapons[weaponClass][name].name
                }
              />
            </div>
            <div className="weaponStatsContainer">
              <div className="weaponStats">
                <label>Kills: {kills}</label>
                <label>Long Range Kills: {longRangeKills}</label>
                <label>Longest Kill: {longestKill}m</label>
                <label>Knocks: {knocks}</label>
                <label>Headshots: {headshots}</label>
              </div>
              <div className="weaponStats">
                <label>Most Kills: {roundMostKills}</label>
                <label>Most Knocks: {roundMostKnocks}</label>
                <label>Most Headshots: {roundMostHeadshots}</label>
                <label>Most Damage: {roundMostDamage}</label>
              </div>
            </div>
            <div className="weaponOverallContainer">
              <label>Damage: {damage}</label>
              <label>Total XP: {xpTotal}</label>
            </div>
          </div>
        );
      }
    });
    return <React.Fragment>{renderedMastery}</React.Fragment>;
  };

  async componentDidMount() {
    const weapons = await getWeapons();

    this.setState({ weapons });
  }

  handleClick = x => {
    this.setState({ weaponClass: x.target.value });
  };

  render() {
    const {
      weaponMastery: { weaponMastery },
      PUBGUSERNAME
    } = this.props;

    const { weapons, weaponClass } = this.state;

    return (
      <div>
        <div className="lifetimeTitle">Weapon Mastery</div>
        <div className="WMcontainer">
          <div className="weaponList">
            {map(weapons, (weapon, key) => {
              return (
                <Button
                  value={key}
                  className={`${key === weaponClass &&
                    'btnFocused'} buttons navBarBtn weaponName`}
                  type="primary"
                  onClick={this.handleClick}
                  key={key}
                >
                  {key}
                </Button>
              );
            })}
          </div>
          <div className="weaponMasteryContainer">
            {PUBGUSERNAME ? (
              weaponMastery && weapons ? (
                this.renderWeaponMastery(weaponMastery)
              ) : (
                <div>Loading ...</div>
              )
            ) : (
              <div>
                Please enter your PUBG Username for your stats to show up
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const enhance = compose(
  graphql(GET_PLAYER_ID_QUERY, {
    name: 'getPlayerId',
    options: ownProps => ({
      variables: {
        region: 'steam',
        playerName: ownProps.PUBGUSERNAME
      }
    })
  }),
  graphql(GET_WEAPONMASTERY_QUERY, {
    name: 'weaponMastery',
    options: ownProps => ({
      variables: {
        region: 'steam',
        playerId: ownProps.getPlayerId.playerId
          ? ownProps.getPlayerId.playerId
          : ''
      }
    })
  })
);

export default withRouter(enhance(WeaponMastery));

import React, { Component } from 'react';
import { map } from 'lodash';
import { BackTop } from 'antd';

import { parseRSS } from '../../utils/rss-parser';

import 'antd/dist/antd.css';
import './index.css';

class News extends Component {
  state = {
    steamFeed: null
  };

  async componentDidMount() {
    const steamFeed = await parseRSS(
      'https://steamcommunity.com/games/578080/rss/'
    );
    this.setState({ steamFeed });
  }

  goToArticle = value => {
    const { content, title } = value.currentTarget.dataset;

    this.props.history.push('/news/article', {
      content: content,
      title: title
    });
  };

  render() {
    const { steamFeed } = this.state;

    return (
      <div>
        <div className="articleTitle">Steam News</div>
        {steamFeed ? (
          map(steamFeed, (item, index) => {
            return (
              <div
                onClick={this.goToArticle}
                key={index}
                className="articleItem"
                data-content={item.content}
                data-title={item.title}
              >
                <div className="newArticleContainer">{item.title}</div>
              </div>
            );
          })
        ) : (
          <div>Loading</div>
        )}
        <BackTop />
      </div>
    );
  }
}

export default News;

import Desert_Main_Full from '../Assets/miramarFull.png';
import Erangel_Main_Full from '../Assets/erangelFull.png';
import Baltic_Main_Full from '../Assets/erangelFull.png';
import DihorOtok_Main_Full from '../Assets/vikendiFull.png';
import Savage_Main_Full from '../Assets/sanhokFull.png';
import Summerland_Main_Full from '../Assets/karakinfull.png';

import Erangel_Main from '../Assets/erangelsmall1.png';
import Baltic_Main from '../Assets/erangelsmall1.png';
import Desert_Main from '../Assets/desertsmall1.png';
import Savage_Main from '../Assets/savagesmall1.png';
import DihorOtok_Main from '../Assets/vikendismall1.png';
import Range_Main from '../Assets/trainingsmall1.png';
import Summerland_Main from '../Assets/karakinsmall1.png';

import Erangel_Main_blur from '../Assets/erangelblur.png';
import Baltic_Main_blur from '../Assets/erangelblur.png';
import Desert_Main_blur from '../Assets/desertblur.png';
import Savage_Main_blur from '../Assets/savageblur.png';
import DihorOtok_Main_blur from '../Assets/vikendiblur.png';
import Range_Main_blur from '../Assets/trainingblur.png';
import Summerland_Main_blur from '../Assets/karakinblur.png';

import Desert_Main_Small from '../Assets/desertsmall.png';
import Erangel_Main_Small from '../Assets/erangelsmall.png';
import Baltic_Main_Small from '../Assets/erangelsmall.png';
import Savage_Main_Small from '../Assets/savagesmall.png';
import DihorOtok_Main_Small from '../Assets/vikendismall.png';
import Range_Main_Small from '../Assets/trainingsmall.png';
import Summerland_Main_Small from '../Assets/karakinsmall.png';

export const mapsFull = {
  Erangel_Main_Full,
  Baltic_Main_Full,
  Desert_Main_Full,
  Savage_Main_Full,
  DihorOtok_Main_Full,
  Summerland_Main_Full
};

export const mapsBlurred = {
  Erangel_Main_blur,
  Baltic_Main_blur,
  Desert_Main_blur,
  Savage_Main_blur,
  DihorOtok_Main_blur,
  Range_Main_blur,
  Summerland_Main_blur
};

export const mapsPlayerMatches = {
  Erangel_Main,
  Baltic_Main,
  Desert_Main,
  Savage_Main,
  DihorOtok_Main,
  Range_Main,
  Summerland_Main
};

export const mapsMatchInfo = {
  Desert_Main_Small,
  Erangel_Main_Small,
  Baltic_Main_Small,
  Savage_Main_Small,
  DihorOtok_Main_Small,
  Range_Main_Small,
  Summerland_Main_Small
};

export const maps = {
  Desert_Main: 'Miramar',
  Erangel_Main: 'Erangel',
  Baltic_Main: 'Erangel',
  Savage_Main: 'Sanhok',
  DihorOtok_Main: 'Vikendi',
  Range_Main: 'Training',
  Summerland_Main: 'Karakin'
};

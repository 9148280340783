import React, { Component } from 'react';
import { Card } from 'antd';

import nodelogo from '../../Assets/nodelogo.png';
import reactlogo from '../../Assets/reactlogo.png';
import vscodelogo from '../../Assets/vscodelogo.png';

import './index.css';
import 'antd/dist/antd.css';

const { Meta } = Card;
class About extends Component {
  render() {
    return (
      <div className="about">
        <h1> What this website is about </h1>
        <div className="text">
          <p>
            This is a solo effort to build a useful website for the PUBG
            community using just the official
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://documentation.playbattlegrounds.com/"
            >
              {' '}
              PUBG API
            </a>
          </p>
        </div>
        <h2>What am I using to develop?</h2>

        <div className="techused">
          <div className="col">
            <Card
              className="cards"
              hoverable
              cover={<img className="image" alt="node" src={nodelogo} />}
            >
              <Meta
                title={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://nodejs.org"
                  >
                    NodeJS
                  </a>
                }
              />
            </Card>
          </div>
          <div className="col">
            <Card
              className="cards"
              hoverable
              cover={<img className="image" alt="react" src={reactlogo} />}
            >
              <Meta
                title={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://reactjs.org/"
                  >
                    ReactJS
                  </a>
                }
              />
            </Card>
          </div>
          <div className="col">
            <Card
              className="cards"
              hoverable
              cover={<img className="image" alt="vscode" src={vscodelogo} />}
            >
              <Meta
                title={
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://code.visualstudio.com/"
                  >
                    VS Code
                  </a>
                }
              />
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
